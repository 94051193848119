var $class="se2--abanner-with-promo-blocks",$name="ABannerWithPromoBlocks",$path="app/components/ABannerWithPromoBlocks/index.js",$this={$class,$name,$path,};import initializeSlider from 'app/components/utilities/initializeSlider';
import lazyHandleOpenModalButtons from 'app/components/utilities/lazyHandleOpenModalButtons';

import 'app/modules/accessibility';
import shell from 'app/modules/shell';
import 'app/partials/image';
import 'app/partials/productSelector';
import 'app/partials/uberflipCard';
import createCardsKeyDownListener from 'app/components/utilities/onNavigation';
import { breakpoints } from 'configs';

export const contentClassName = 'animated-content-body';
export const descriptionClassName = 'card-text';
export const backgroundClassName = 'fixed-background';
export const slideSelector = '.slide';
export const swiperContainerSelector = '.swiper-container';
export const uberflipContainerSelector = '.uberflip-cards';

export default shell.registerComponent($this, ({ addEventListener, mount }) => {
  mount((element) => {
    let slider = element.offsetWidth <= breakpoints.xs.max ? initializeSlider(element, addEventListener) : null;

    const onWindowResize = () => {
      if (element.offsetWidth <= breakpoints.xs.max && !slider) {
        slider = (initializeSlider(element, addEventListener));
      }

      if (element.offsetWidth <= breakpoints.xs.max) {
        slider?.draggable.enable();
        slider?.onResize();
      } else {
        slider?.draggable.disable();
        slider?.resetPosition();
      }
    };

    lazyHandleOpenModalButtons(addEventListener, element);

    addEventListener(
      element.querySelector(uberflipContainerSelector),
      'keydown',
      createCardsKeyDownListener(slideSelector),
    );

    addEventListener(window, 'resize', onWindowResize);
  });
});
